import React, { useEffect } from 'react'
// import { navigate } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = actions => {
  const { navigate } = actions
  useEffect(() => {
    navigate('/')
  })

  return (
    <Layout>
      <h1>404 NOT FOUND</h1>
      <a href="/">HOME</a>
    </Layout>
  )
}

export default NotFoundPage

// import { useEffect } from 'react'
// import { navigate } from '@reach/router'

// export default () => {
//   useEffect(() => {
//     navigate('/')
//   }, [])
//   return null
// }
////Auto Return doesn't seem to refresh the home page for animations... parhaps link?
